<template>

    <div class="fullScreenCanvas" ref="fullScreenCanvas" style="display: flex; flex-grow: 1; justify-content: center; position: relative">

        <div class="action-bar">

            <ViewGridIcon
                class="btn grid_view"
                @click="visibleLeftPreviewbar = !visibleLeftPreviewbar"
            />

            <left-icon
                :class="{ disabled: !flipbook.canFlipLeft }"
                class="btn left"
                @click="handleFlipLeft"
            />

            <minus-icon
                :class="{ disabled: !flipbook.canZoomOut }"
                class="btn minus"
                @click="handleZoomOut"
            />

            <span class="page-num">{{ flipbook.page }}/{{ flipbook.numPages }}</span>

            <plus-icon
                :class="{ disabled: !flipbook.canZoomIn }"
                class="btn plus"
                @click="handleZoomIn"
            />

            <right-icon
                :class="{ disabled: !flipbook.canFlipRight }"
                class="btn right"
                @click="handleFlipRight"
            />

            <a v-if="showDownloadButton" :href="downloadUrl" ref="downloadELm" download class="btn download">
                <download-icon
                    class="btn download"
                />
            </a>

            <fullscreen-icon
                class="btn fullscreen"
                @click="handleBrowserFullscreenAction"
            />

        </div>

        <PreviewSidebar
            :visible="visibleLeftPreviewbar"
            :pages="pages"
            :page="selectedPreviewPage"
            @change="handlePreviewSelectionChange"
            @hide="handlePreviewHide"
            @show="handlePreviewShow"
        />

        <div id="overlay" class="preparing-pages-message" v-if="preparingPages">
            <div style="position: absolute; vertical-align: top; padding: 5px;">PDF wird vorbereitet...</div>
        </div>

        <Flipbook
            ref="flipbook"
            :zooms="zooms"
            :centering="true"
            :pages="fpages"
            :pagesHiRes="fpagesHiRes"
            :startPage="startPage"
            class="flipbook"
            @flip-left-end="handleFlipFinished"
            @flip-right-end="handleFlipFinished"
        />

    </div>


</template>

<script>

import 'vue-material-design-icons/styles.css';
import Flipbook from 'flipbook-vue';
import LeftIcon from 'vue-material-design-icons/ChevronLeftCircle';
import RightIcon from 'vue-material-design-icons/ChevronRightCircle';
import PlusIcon from 'vue-material-design-icons/PlusCircle';
import MinusIcon from 'vue-material-design-icons/MinusCircle';
import FullscreenIcon from 'vue-material-design-icons/Fullscreen';
import ViewGridIcon from 'vue-material-design-icons/ViewGrid';
import DownloadIcon from 'vue-material-design-icons/Download';

import PreviewSidebar from '@/components/PreviewSidebar';

export default {
    name: "FlipbookWithUI",
    components: {
        PreviewSidebar,
        Flipbook, LeftIcon, RightIcon, PlusIcon, MinusIcon, FullscreenIcon, ViewGridIcon, DownloadIcon,
        // Sidebar
    },
    props: ['pages', 'pagesHiRes', 'urlPreparePages', 'downloadUrl'],
    data: function () {
        return {
            // the 0.99 is used to shrink the canvas a bit to show nicer borders
            // te offset the new unaligned center
            zooms: [0.96, 1.8, 4],

            // preview sidebar status and visibility
            visibleLeftPreviewbar: false,
            selectedPreviewPage: 1,

            // flipbook component property
            // used to directly navigate to a page ( 1 indexed)
            startPage: 1,

            // the flipbook property will be replaced by the flipbook component object after it was mounted
            flipbook: {
                canFlipLeft: false,
                canFlipRight: false,
                canZoomIn: false,
                canZoomOut: false,
                page: 0,
                numPages: 0
            },

            // loading indicator if a background request should trigger the page image calculations
            preparingPages: true,

            // status for browser fullscreen action
            // browserFullscreen: false,
        }
    },
    computed: {

        fpages: function () {
            if (this.pages && this.pages.length > 0) {
                return [null, ...eval(this.pages)];
            }
            // default
            return [null, 'images/output-01.jpg', 'images/output-02.jpg', 'images/output-03.jpg', 'images/output-04.jpg'];
        },

        fpagesHiRes: function () {
            if (this.pagesHiRes && this.pagesHiRes.length > 0) {
                return [null, ...eval(this.pagesHiRes)];
            }
            if (this.pages && this.pages.length > 0) {
                return eval(this.pages);
            }
            return [null, 'images/output-01.jpg', 'images/output-02.jpg', 'images/output-03.jpg', 'images/output-04.jpg'];
        },

        showDownloadButton: function() {
            if(this.downloadUrl && this.downloadUrl !== '') {
                return true;
            }
            return false;
        }
    },
    methods: {

        handlePreviewShow() {
            // visible previewbar update current page
            this.selectedPreviewPage = this.flipbook.page;
            this.startPage = null;
        },

        handlePreviewHide() {
            this.visibleLeftPreviewbar = false;
        },

        handlePreviewSelectionChange(newPage) {
            this.selectedPreviewPage = null
            this.startPage = newPage
        },

        handleFlipFinished() {
            this.selectedPreviewPage = this.flipbook.page;
            this.startPage = null;
        },

        handleFlipRight() {
            this.flipbook.flipRight();
        },
        handleFlipLeft() {
            this.flipbook.flipLeft();
        },
        handleZoomIn() {
            this.flipbook.zoomIn();
        },
        handleZoomOut() {
            this.flipbook.zoomOut();
        },

        handleDownload() {
            //window.open(this.downloadUrl, '_blank');
            this.$refs.downloadELm.href
        },

        handleBrowserFullscreenAction() {
            let fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;

            if (!fullscreenElement) {
                let elem = this.$refs.fullScreenCanvas;

                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) { /* Safari */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) { /* IE11 */
                    elem.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                }
            }
        },




    },
    mounted() {
        // try to inject the object right from the start
        // if the overlay is shown later this will be set at the handleFullscreenShowEvent Event
        if (this.$refs.flipbook) {
            this.flipbook = this.$refs.flipbook;
        }

        window.addEventListener('keydown', (ev) => {
            let flipbook = this.$refs.flipbook;
            if (ev.keyCode == 37 && flipbook.canFlipLeft) {
                flipbook.flipLeft()
            }
            if (ev.keyCode == 39 && flipbook.canFlipRight) {
                flipbook.flipRight()
            }
        });
        let that = this;
        if (this.urlPreparePages !== undefined && this.urlPreparePages !== '') {
            fetch(this.urlPreparePages)
                .then(() => {
                    that.preparingPages = false;
                })
        } else {
            that.preparingPages = false;
        }


    },
    updated() {
        // this is a hack to fix a problem centering the viewer inside the canvas
        this.$refs.flipbook.onResize();
    },
    watch: {}
}
</script>

<style>

/****************************************************************************************************************
  this is the overlay grey background outside the pdf canvas, the padding is used to keep some space when flipping
*****************************************************************************************************************/
.fullScreenCanvas {
    background-color: #CCC;
    /*padding: 0 20px 0 20px*/
}


/****************************************************************************************************************
  the flipbook area outside the pdf canvas - should resize with the parent container
*****************************************************************************************************************/

.flipbook {
    height: 100%;
    width: 100%;
    /*border: 1px solid red;*/
    /*padding-top: 10px;*/
    /*padding-bottom: 10px;*/
}

.flipbook .viewport {
    /*height: 50%;*/
    /*width:  50%;*/
    /*padding: 50px 0 50px 0;*/
    /*height: calc(100% - 100px) !important;*/
    /*border: 1px solid blue;*/

}


.flipbook .bounding-box {
    box-shadow: 0 0 20px #000;

}

.flipbook .flipbook-container {
    /* counter the 0.98 zoom level pushing everything to the left side */
    left: 2%;
}

/****************************************************************************************************************
  the action bar contains the zoom and flip buttons and is positioned below the pdf canvas
*****************************************************************************************************************/

.action-bar {
    bottom: 10px;
    position: absolute;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    display: flex;
    background-color: #FFF;
    font-size: 32px;
    padding: 5px;

    z-index: 1000;
    box-shadow: 0 0 10px #999;
}

.action-bar .btn {
    /*font-size: 30px;*/
    color: rgba(0,0,0,0.5);
}

.action-bar .btn svg {
    bottom: 0;
}

.action-bar .btn:not(:first-child) {
    margin-left: 10px;
}

.has-mouse .action-bar .btn:hover {
    /*color: #ccc;*/
    color: rgba(0,0,0,0.75);
    filter: drop-shadow(1px 1px 5px #000);
    cursor: pointer;
}

.action-bar .btn:active {
    filter: none !important;
}

.action-bar .btn.disabled {
    color: rgba(0,0,0,0.25);
    pointer-events: none;
}

.action-bar .page-num {
    font-size: 16px;
    position: relative;
    top: 7px;
    margin-left: 10px;
}

/****************************************************************************************************************
  left preview-bar
*****************************************************************************************************************/

.fullScreenCanvas {

}

.preparing-pages-message {
    color: #000;
    opacity: 0.6;
}

</style>