import Vue from 'vue';
import vueCustomElement from 'vue-custom-element';
import App from './App.vue';

import PrimeVue from 'primevue/config';

Vue.use(vueCustomElement);
Vue.config.productionTip = false;

Vue.use(PrimeVue);


// we use the custom element to avoid the use of the shadow dom
Vue.customElement('pdf-flip-book', App);