<template>
    <div class="app"
         :class="{ 'has-mouse': hasMouse }"
         @touchstart="hasMouse = false"
    >

        <!--
            Display Inline Mode
        -->
        <div v-if="displayMode === 'inline'"
             class="display-inline-root-div"
             style="width: 100%; aspect-ratio: 12 / 9;"
        >
            <div style="display: flex; flex-direction: column; height: 100%;">
                <FlipbookWithUI :pages="pages" :pagesHiRes="pagesHiRes" :urlPreparePages="urlPreparePages" :downloadUrl="downloadUrl"/>
            </div>
        </div>

        <!--
            Display Popup Mode
        -->
        <div v-if="displayMode === 'popup'"
             @click="handleShowLightbox"
             class="display-popup-root-div"
        >
            <img v-if="pdfBlankPreviewImage && pdfBlankPreviewImage !== ''"
                 :src="pdfBlankPreviewImage"
                 class="image-preview-image"
            >
            <div v-if="textPreviewImage && textPreviewImage !== ''"
                 class="text-preview-image"
            >{{ textPreviewImage }}</div>
        </div>


        <Sidebar
            :visible.sync="visibleLightbox"
            position="full"
            :autoZIndex="true"
            :baseZIndex="1000"
            :modal="true"
            :showCloseIcon="false"
        >
            <div style="display: flex; flex-direction: column; height: 100%;">
                <div class="sidebar-close-button">
                    <close-icon
                        class="btn close"
                        @click="visibleLightbox=false"
                    />
                </div>
                <FlipbookWithUI :pages="pages" :pagesHiRes="pagesHiRes" :urlPreparePages="urlPreparePages" :downloadUrl="downloadUrl"/>

            </div>

        </Sidebar>

    </div>

</template>

<script>
import Sidebar from 'primevue/sidebar';
import FlipbookWithUI from './components/FlipbookWithUI';
import CloseIcon from 'vue-material-design-icons/Close';

import PdfBlankPreviewImage from '@/assets/pdf-preview-empty.png';

export default {
    name: 'App',
    components: {
        FlipbookWithUI,
        Sidebar,
        CloseIcon
    },
    props: {
        'displayMode': {
            default: "inline",
            type: String
        },
        'pages': String,
        'pagesHiRes': String,
        'urlPreparePages': {
            default: "",
            type: String
        },
        'urlPreviewImage': {
            default: PdfBlankPreviewImage,
            type: String
        },
        'textPreviewImage': {
            default: '',
            type: String
        },
        'downloadUrl': {
            default: '',
            type: String
        }
    },
    data: function () {
        return {
            hasMouse: true,
            visibleLightbox: false,
        }
    },
    methods: {
        handleShowLightbox() {
            this.visibleLightbox = true;
        },
    },
    computed: {
        pdfBlankPreviewImage: function() {
            if(this.urlPreviewImage && this.urlPreviewImage !== '') {
                return this.urlPreviewImage;
            }
            return PdfBlankPreviewImage;
        }
    }
}
</script>

<style>

.app {
    /*font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
    /*-webkit-font-smoothing: antialiased;*/
    /*-moz-osx-font-smoothing: grayscale;*/
    /*height: calc(100vh - 20px);*/
    display: flex;
    flex-direction: column;
    align-items: center;
    /*background-color: #333;*/
    /*color: #ccc;*/
    overflow: hidden;
}

a {
    color: inherit;
}

.display-popup-root-div {
    display: inline-block;
}

.text-preview-image {
    text-align: center;
    padding: 3px;
}


/****************************************************************************************************************
  Primeview Sidebar styles
*****************************************************************************************************************/

.p-sidebar-active {
    /*background-color: green;*/
    /*opacity: 1;*/
}


.p-sidebar-content {
    height: 100vh;
    overflow: hidden;
}



</style>
