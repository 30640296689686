<template>
    <div class="visibleLeftPreviewbarParent">
        <div v-show="isVisible" class="visibleLeftPreviewbar">
            <div class="sidebar-close-button">
                <close-icon
                    class="btn close"
                    @click="handleCloseSidebar"
                />
            </div>
            <div ref="sidebarLeftContent" class="sidebar-left-content" style="height: 100%">

                <ScrollPanel style="width: 100%; height: 100%">
                    <Listbox v-model="currentPreview" :options="previewData" optionLabel="name" @change="handlePreviewSelectionChange">
                        <template #option="slotProps">
                            <div class="preview-item-box">
                                <!-- <img class="preview-item-box-image" :alt="slotProps.option.page" :data-deferred-src="slotProps.option.src" :src="slotProps.option.src"/> -->
                                <img class="preview-item-box-image" :alt="slotProps.option.page" :data-deferred-src="slotProps.option.src" :src="pdfBlankPreviewImage"/>
                            </div>
                        </template>
                    </Listbox>
                </ScrollPanel>

            </div>
        </div>
    </div>

</template>

<script>

import CloseIcon from 'vue-material-design-icons/Close';
import ScrollPanel from 'primevue/scrollpanel';
import Listbox from 'primevue/listbox';
import PdfBlankPreviewImage from '@/assets/pdf-preview-empty.png';

export default {
    name: "PreviewSidebar",
    props: [
        'visible',
        'pages',
        'page'  // current page number 1 indexed
    ],
    components: {
        CloseIcon,
        ScrollPanel,
        Listbox
    },
    data: function() {
        return {
            isVisible: this.visible,
            currentPreview: null,

            // preview data is 0 indexed
            previewData: [],
            pdfBlankPreviewImage: PdfBlankPreviewImage,
        }
    },
    methods: {
        handleCloseSidebar: function () {
            this.isVisible = false;
            this.$emit('hide');
        },
        handlePreviewSelectionChange() {
            if (this.currentPreview) {
                this.$emit('change', this.currentPreview.page);
            }
        },
        setCurrentPage(page) {
            try {
                const foundPage = this.previewData.find(function(elm) {
                    return elm.page === page;
                })
                this.currentPreview = foundPage;
                //this.currentPreview = this.previewData[page - 1];
            } catch (ex) {
                // ignore;
            }
        }
    },
    created() {
        // prepare the preview data
        if (this.pages && this.pages.length > 0) {
            this.previewData = [null, ...eval(this.pages)].filter((value) => (value !== null ? true : false)).map((value, index) => ({page: index + 1, src: value}));
            this.setCurrentPage(this.page);
        }
    },
    mounted() {
        /**
         * lazy load the preview images here
         */
        var observer = new IntersectionObserver(function (entries) {

            for (let i = 0; i < entries.length; i++) {
                // entries[i]['intersectionRatio']
                // entries[i]['isIntersecting']
                // entries[i]['target']

                // isIntersecting is true when element and viewport are overlapping
                // isIntersecting is false when element and viewport don't overlap
                if (entries[i].isIntersecting === true) {
                    let real_src = entries[i].target.attributes.getNamedItem('src')
                    let data_src = entries[i].target.attributes.getNamedItem('data-deferred-src')

                    if (data_src) {
                        if (!real_src || real_src.value !== data_src.value) {
                            // entries[i].target.setAttribute('src', data_src.value);

                            entries[i].target.src = data_src.value;
                        }
                    }
                }
            }
        }, {threshold: [0]});

        const rootElm = this.$refs.sidebarLeftContent;
        const elmImages = rootElm.querySelectorAll(".preview-item-box-image");
        elmImages.forEach(function (elm) {
            observer.observe(elm);
        })

        if (this.isVisible === true) {
            this.$emit('show');
        }
    },
    watch: {
        visible: function(value) {
            if(value === true) {
                this.setCurrentPage(this.page);
                this.isVisible = true;
                this.$emit('show');
            }
            if(value === false) {
                this.isVisible = false;
                this.$emit('hide');
            }

        },
        page: function(value) {
            if(value) {
                this.setCurrentPage(value);
            }
        }
    }
}
</script>

<style>

.visibleLeftPreviewbarParent {
    /* give this a relative position to allow the absolute positioned child get a correct 100% height */
    position: relative;
}

.visibleLeftPreviewbar {
    position: absolute;
    height: 100%;
    left: 0;
    width: 20rem;

    /*background-color: white !important;*/
    z-index: 1000;
}

.sidebar-left-content {
    padding: 6px;
    background-color: #666;
}

.sidebar-close-button {
    z-index: 2000;
    position: absolute;
    right: 0;
    text-align: center;
    font-size: 32px;
    padding: 8px 0;
    height: 48px;
    width: 46px;
    color: #444;
    cursor: pointer;
}

.preview-item-box {
    margin: 7px 0px 7px 10px;
    padding: 3px 0px 3px 3px;
    opacity: 1;
    /*min-height: 200px;*/
}

.preview-bar img {
    width: 200px;
    height: auto;
}

/* highlight selected preview */

.p-listbox-item.p-highlight .preview-item-box {
    opacity: 0.7;
}

</style>